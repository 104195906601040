html {
  min-height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: sans-serif;
  background: url("/src/assets/mario-bg.png") no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #95e8f3;
}

form {
  padding: 20px;
  margin-top: 60px;
}

form button,
form h5 {
  margin: 20px 0;
}

input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label {
  color: #ec407a !important;
}
